import { createRouter, createWebHistory } from 'vue-router';
import TheBooks from '../views/TheBooks.vue';
import TheAbout from '../views/TheAbout.vue';
import TheHome from '../views/TheHome.vue';
import TheFilms from '../views/TheFilms.vue';
import TheLogin from '../views/TheLogin.vue';
import store from '../store';

const routes = [
    { name: 'TheHome', path: '/', component: TheHome },
    { name: 'TheFilms', path: '/films', component: TheFilms },
    { name: 'TheBooks', path: '/books', component: TheBooks },
    { name: 'TheAbout', path: '/about', component: TheAbout },
    { name: 'Login', path: '/login', component: TheLogin },
]

const router = new createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to) => {
    if (!store.state.token && to.name !== 'Login') {
      return { name: 'Login', query: {redirect: to.path} }
    }
})

export default router;