<template>
<div>
    <input 
        class="dropdown-input"
        @focus="showOption"
        @blur="hideOption"
        v-model="inputVal" />
    <button
        class="dropdown button"
        @blur="hideOption"
        @click="toggleOption" >
        <i class="fa-solid fa-angle-down" ></i>
    </button>
    <div class="dropdown-content" v-show="showOptions">
        <div 
            class="dropdown-item"
            v-for="val in vals"
            @mousedown="selectValue(val)"
            :key="val">{{ val }}
        </div>
    </div>
</div>
</template>
<script>
export default {
    props: {
        field: String,
        values: Array,
        inputValue: String,
    },
    data() {
        return {
            inputVal: "",
            showOptions: false,
            selected: "",
            vals: [],
        }
    },
    mounted() {
        this.inputVal = this.inputValue;
    },
    watch: {
        inputVal: function() {
            if (!this.inputVal)
                return
            this.vals = this.values.filter(word => word.toLowerCase().includes(this.inputVal.toLowerCase(), 0))
        }
    },
    methods: {
        selectValue(value) {
            this.selected = value;
            this.inputVal = value;
            this.$emit('selected', this.selected);
        },
        toggleOption() {
            this.showOptions = !this.showOptions
        },
        showOption() {
            this.showOptions = true;
        },
        hideOption() {
            this.showOptions = false;
            this.selected = this.inputVal;
            this.$emit('selected', this.selected);
        }
    }
}
</script>
<style scoped>
select {
    max-width: 14.5rem;
}

.dropdown {
    background-color: rgba(0, 0, 0, 0);
}

i:hover {
    color: rgba(222, 22, 86, 1);
}

.dropdown-content {
    position: absolute;
    background-color: #f8f8F8;
    min-width: 14em;
    max-width: 15rem;
    max-height: 15rem;
    margin-top: .5rem;
    border: 1px solid rgba(222, 22, 86, 1);
    /* border-top-color: rgba(0,0,0,0); */
    box-shadow: 0px -8px 34px 0px rgba(0,0,0,0.05);
    overflow: auto;
    z-index: 1;
}

.dropdown-input {
    width: 14em;
    margin-right: 1em;
}

.dropdown-item {
    color: black;
    line-height: 1em;
    text-decoration: none;
    padding: 0.5em;
    display: block;
    cursor: pointer;
}
</style>