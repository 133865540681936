<template>
  <div>
      <header>
        <h1>Login</h1>
      </header>
      <main @keydown.enter="login()">
        <label for="username">Username</label>
        <input id="username" type="text" v-model="username"/>
        <label for="password">Password</label>
        <input id="password" type="password" v-model="password"/>
        <button @click="login">Login</button>
      </main>
      <footer>
      </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
        username: "",
        password: ""
    }
  },
  methods: {
      login() {
        if (this.username !== "" && this.password !== "")
            this.$store.dispatch('login', {'username': this.username, 'password': this.password}).then(() => {
              this.username = ""
              this.password = ""
              this.$router.push(this.$route.query.redirect || '/books')
            })
      }
  }
}
</script>
<style scoped>
button {
    margin-top: 0.5rem
}
label {
    margin: 0.2rem;
    text-align: left;
}
input {
    background-color: rgba(0.1, 0.1, 0.1, 0.1);
    border: none
}
input:hover {
    background-color: rgba(0.1, 0.1, 0.1, 0.1);
}
main {
    width : 20rem;
    align-self: center;
    display: flex;
    align-content: center;
    flex-direction: column;
}
div { 
    flex-direction: column;
    display: flex;
}
</style>