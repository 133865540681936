<template>
  <div class="about">
      <header>
        <h1>About</h1>
      </header>
      <main>
        <h2>Home Library</h2>
        <p>
          
        </p>
        <p>
          Made with <a href="https://vuejs.org/"><b>VueJS</b></a> and <a href="https://fastapi.tiangolo.com/"><b>FastAPI</b></a> by <a href="https://julien.aldon.fr/"><b><em>Julien Aldon</em></b></a>
        </p>
      </main>
      <footer>
      </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style scoped>
a {
  text-decoration: none;
}

p {
  text-align: left;
}

h2 {
  text-align: left;
}
</style>