<template>
<table>
  <tr>
    <th v-bind:style="{ 'min-width': this.width }" v-for="head in headings" :key="head">{{ head }}</th>
  </tr>
  <slot></slot>
</table>
</template>

<script>
export default {
  name: "TableView",
  props: {
    headings: Array,
    width: String
  },
};
</script>
<style scoped>
th {
  text-align: left;
}
</style>