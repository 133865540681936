<template>
<div>
  <nav class="header">
    <router-link class="router" to="/">Home</router-link>
    <router-link class="router" to="/about">About</router-link>
    <router-link class="router" to="/films">Film library</router-link>
    <router-link class="router" to="/books">Book library</router-link>
    <router-link v-if="!$store.state.token" class="router" to="/login">Login</router-link>
    <a class="router" v-else @click="logout">Log out</a>
  </nav>
  <router-view class="view"/>
</div>
</template>

<script>
import { routerLink, routerView} from 'vue-router';

export default {
  name: 'App',
  components: {
    routerLink,
    routerView
  },
  beforeMount() {
    let token = localStorage.getItem('token')
    if (token) {
      this.$store.state.token = localStorage.getItem('token')
    }
    else {
      this.$store.state.token = ""
      this.$router.push('/login')
    }
    this.$store.dispatch('getBooks', {page: 0, search: "", order: ""})
    this.$store.dispatch('getFilms', {page: 0, search: "", order: ""})
  }, 
  methods: {
    logout() {
      this.$store.dispatch('logout')
      this.$router.push('/login')
    }
  }
}
</script>

<style>
.red {
  background-color: rgba(222, 22, 86); 
  color: #fff;
}

.red:hover {
  background-color: rgba(150, 22, 86); 
}

.green {
  background-color: rgba(22, 222, 86); 
}

.green:hover {
  background-color: rgba(22, 150, 86); 

}
header {
  width: 60.5vw;
  display: flex;
}

.header {
  display: flex;
  
  /* width: 100%; */
}
body {
  margin: 0px;
}

.button {
  border: none;
  padding: 0.2rem;
  margin: 0.3rem;
}


.view {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.router {
  flex: 1 1 auto;
  background-color: rgba(222, 22, 86); 
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition: background-color 0.5s ease-in;
}

.router:focus {
  background-color: rgba(150, 22, 86); 
}

.router:hover {
  background-color: rgba(150, 22, 86); 
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav a {
  padding: 1rem;
  margin: 0px;
}

table {
  table-layout: fixed;
}

.button-panel {
  display: flex;
  flex-flow: row;
}

tr:nth-child(2n+1) {
  background-color: rgba(11, 11, 11, 0.1);
}

tr {
  text-align: left;
}

tr:hover {
  background-color: rgba(11, 11, 11, 0.1);
}

h1 {
  flex: 1 1 auto;
  text-align: left;
}

td { 
    overflow: hidden; 
    text-overflow: ellipsis; 
    word-wrap: break-word;
}

.AddButton {
  border: none;
  height: 2rem;
  width: 2rem;
  margin-right: 0.2rem; 
  justify-content: flex-end;
  align-self: center;
}

.edit {
  width: 2rem;
  height: 2rem;
}

.AddForm {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation-duration: 0.3s;
  animation-name: lineInsertedIn;
  transition: height 0.3s;
}
.AddForm input {
  margin: 1rem;
}

.AddForm .button {
  align-self: center;
  /* max-width: 5rem;
  min-width: 5rem; */
  align-self: flex-end
}

.AddForm label {
  align-self: flex-start;
}
</style>
