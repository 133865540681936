<template>
<div>
  <header>
    <h1>Home</h1>
  </header>
  <main>
    <h2>Welcome to the Library</h2>
    <p>You can manage, edit, delete book references from the home library</p>
  </main>
</div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style scoped>
a {
  text-decoration: none;
}

p {
  text-align: left;
}

h2 {
  text-align: left;
}
</style>