import { createStore } from 'vuex';
import { ROOT_FQDN } from '@/config';

const store = createStore({
    state() {
        return {
            books: [
            ],
            films: [
            ],
            token: "",
            bookPageNb: 0,
            bookCurrentPage: 0,
            filmPageNb: 0,
            filmCurrentPage: 0,
        }
    },
    mutations: {
        setBooks(state, books) {
            state.books = books.result
        },
        setFilms(state, films) {
            state.films = films
        },
        logout(state) {
            state.token = ""
        },
        addToken(state, token) {
            state.token = token
        },
        removeBook(state, id) {
            let index = state.books.findIndex(book => book.biblio_Index === id)
            state.books.splice(index, 1)
        },
        editBook(state, payload) {
            let index = state.books.findIndex(book => book.biblio_Index === payload.id)
            state.books.splice(index, 1, payload.newBook)
        },
        addBook(state, book) {
            state.books.push(book);
        },
        removeFilm(state, id) {
            let index = state.films.findIndex(film => film.Number === id)
            state.films.splice(index, 1)
        },
        editFilm(state, payload) {
            let index = state.films.findIndex(film => film.Number === payload.id)
            state.films.splice(index, 1, payload.newfilm)
        },
        addFilm(state, film) {
            state.films.push(film);
        },
        setBookPageNb(state, nb) {
            state.bookPageNb = nb
        },
        setBookCurrentPage(state, page) {
            state.bookCurrentPage = page
        },
        setFilmCurrentPage(state, page) {
            state.filmCurrentPage = page
        }
    },
    getters: {
        getBooks(state) {
            return state.books;
        },
        getBookCurrentPage(state) {
            return state.bookCurrentPage
        },
        getBookPageNb(state) {
            return state.bookPageNb
        },
        getFilms(state) {
            return state.films;
        },
        getFilmCurrentPage(state) {
            return state.filmCurrentPage
        },
        getFilmPageNb(state) {
            return state.filmPageNb
        },
    },
    actions: {
        logout(ctx) {
            ctx.commit('logout')
            localStorage.setItem('token', '')
        },
        async login(ctx, info) {
            let username = info.username
            let password = info.password
            const data = new FormData()
            data.append('username', username)
            data.append('password', password)
            return await fetch(ROOT_FQDN + '/token', {
                method: 'POST',
                body: data
            }).then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    return null
                }
            }).then(res => {
                if (res !== null) {
                    ctx.commit('addToken', res.access_token)
                    localStorage.setItem('token', res.access_token)
                    ctx.dispatch('getBooks', {page: 0, search: "", order:""})
                    ctx.dispatch('getFilms', {page: 0, search: "", order:""})
                }
            })
        },
        async removeBook({dispatch, commit, state}, id) {
            await fetch(ROOT_FQDN+'/book/'+id, {
                method: 'DELETE',
                headers: {
                    "Authorization": 'Bearer ' + state.token,
                    "Content-Type": "application/json"
                },
            }).then((response) => {
                if (response.ok) {
                    commit('setBookPageNb', response.headers.get('x-nbpage'))
                    return response.json()
                } else
                    return null
            }).then(res => {
                if (res === null)
                {
                    dispatch('logout')
                    return null
                }
                return res
            })            
            commit('removeBook', id)
        },
        async editBook({dispatch, commit, state}, payload) {
            let data = {
                title: payload.newBook.title,
                author: payload.newBook.author,
                type: payload.newBook.type,
                editor: payload.newBook.editor
            }
            await fetch(ROOT_FQDN+'/book/'+payload.id, {
                method: 'PUT',
                headers: {
                    "Authorization": 'Bearer ' + state.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(res => {
                if (res.ok) {
                    return res.json()
                } else 
                    return null
            }).then((res) => {
                if (res === null)
                    dispatch('logout')
                dispatch('getBooks', {page: 0, search: payload.search, order:""})
            })
            commit('editBook', payload)
        },
        async addBook({dispatch, commit, state}, book) {
            let data = {
                title: book.Titre,
                author: book.Auteur,
                type: book.Type,
                editor: book.Editeur
            }
            await fetch(ROOT_FQDN+'/books', {
                method: 'POST',
                headers: {
                    "Authorization": 'Bearer ' + state.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then((response) => {
                commit('setBookPageNb', response.headers.get('x-nbpage'))
                if (response.ok)
                    return response.json()
                else
                    return null
            }).then(res => {
                if (res === null)
                {
                    dispatch('logout')
                    return null
                }
                return res
            })            
            commit('addBook', book)
        },
        async getBooks({dispatch, commit, state}, payload) {
            let test =  payload.page ? "?page=" + payload.page : ""
            if (payload.page)
                test += '&'
            else
                test += '?'
            test += payload.search ? "search="+payload.search : ""
            await fetch(ROOT_FQDN+'/books'+test, {
                method: 'GET',
                headers: {
                    "Authorization": 'Bearer ' + state.token,
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                commit('setBookPageNb', response.headers.get('x-nbpage'))
                if (response.ok)
                    return response.json()
                else
                    return null
            }).then(res => {
                if (res === null)
                {
                    dispatch('logout')
                    return null
                }
                commit('setBooks', res)
                return res
            })
        },
        async removeFilm({dispatch, commit, state}, id) {
            await fetch(ROOT_FQDN+'/film/'+id, {
                method: 'DELETE',
                headers: {
                    "Authorization": 'Bearer ' + state.token,
                    "Content-Type": "application/json"
                },
            }).then((response) => {
                if (response.ok) {
                    commit('setFilmPageNb', response.headers.get('x-nbpage'))
                    return response.json()
                } else
                    return null
            }).then(res => {
                if (res === null)
                {
                    dispatch('logout')
                    return null
                }
                return res
            })            
            commit('removeFilm', id)
        },
        async editFilm({dispatch, commit, state}, payload) {
            let data = {
                title: payload.newfilm.Title,
                actors: payload.newfilm.Actors,
                director: payload.newfilm.Director,
                type: payload.newfilm.Type,
                producer: payload.newfilm.Producer,
                length: payload.newfilm.Length
            }
            await fetch(ROOT_FQDN+'/film/'+payload.id, {
                method: 'PUT',
                headers: {
                    "Authorization": 'Bearer ' + state.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then(res => {
                if (res.ok) {
                    return res.json()
                } else 
                    return null
            }).then((res) => {
                if (res === null)
                    dispatch('logout')
                dispatch('getFilms', {page: 0, search:"", order:""})
            })
            commit('editFilm', payload)
        },
        async addFilm({dispatch, commit, state}, film) {
            let data = {
                title: film.Title,
                actors: film.Actors,
                director: film.Director,
                type: film.Type,
                producer: film.Producer,
                length: film.Length
            }
            await fetch(ROOT_FQDN+'/films', {
                method: 'POST',
                headers: {
                    "Authorization": 'Bearer ' + state.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            }).then((response) => {
                commit('setFilmPageNb', response.headers.get('x-nbpage'))
                if (response.ok)
                    return response.json()
                else
                    return null
            }).then(res => {
                if (res === null)
                {
                    dispatch('logout')
                    return null
                }
                return res
            })            
            commit('addFilm', film)
        },
        async getFilms({dispatch, commit, state}, payload) {
            let test =  payload.page ? "?page=" + payload.page : ""
            if (payload.page)
                test += '&'
            else
                test += '?'
            test += payload.search ? "search="+payload.search : ""
            await fetch(ROOT_FQDN+'/films'+test, {
                method: 'GET',
                headers: {
                    "Authorization": 'Bearer ' + state.token,
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.ok)
                    return response.json()
                else
                    return null
            }).then(res => {
                commit('setFilms', res)
                if (res === null) {
                    dispatch('logout');
                    return null
                }
                return res
            })
        },
        async fetchPageBooks({dispatch, commit}, payload) {
            commit('setBookCurrentPage', payload.page)
            if (payload.nooption) {
                dispatch('getBooks', {page: payload.page, search: "", order: ""})
            } else {
                dispatch('getBooks', {page: payload.page, search: payload.search, order: payload.sort})
            }
        },
        async fetchPageFilms({dispatch, commit}, payload) {
            commit('setFilmCurrentPage', payload.page)
            if (payload.nooption) {
                dispatch('getFilms', {page: payload.page, search: "", order: ""})
            } else {
                dispatch('getFilms', {page: payload.page, search: payload.search, order: payload.sort})
            }
        },
        async getBookField({dispatch, state}, field) {
            return await fetch(ROOT_FQDN+'/books/' + field, {
                method: 'GET',
                headers: {
                    "Authorization": 'Bearer ' + state.token,
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.ok)
                    return response.json()
                else
                    return null
            }).then(res => {
                if (res === null) {
                    dispatch('logout');
                    return null
                }
                return res
            })
        },
        async getFilmField({dispatch, state}, field) {
            return await fetch(ROOT_FQDN+'/films/' + field, {
                method: 'GET',
                headers: {
                    "Authorization": 'Bearer ' + state.token,
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.ok)
                    return response.json()
                else
                    return null
            }).then(res => {
                if (res === null) {
                    dispatch('logout');
                    return null
                }
                return res
            })
        }
    }
})

export default store;