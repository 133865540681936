<template>
    <div class="searchBox">
        <input class="search" type="text" v-model="search"/>
        <button class="searchButton" @click="clearSearch" >x</button>
    </div>
</template>
<script>
export default {
    props: {
        page: String,
    },
    watch: {
        search: function() {
            this.$emit('changeSearch', this.search);
            this.$store.dispatch('fetchPage'+this.page, {page: 0, nooption: false, search: this.search, order: this.order})
        }
    },
    methods: {
        clearSearch() {
            this.$store.dispatch('fetchPage'+this.page, {page: 0, nooption: true})
            this.search = ""
        },
    },
    data() {
        return {
            search: "",
        }
    }
}
</script>
<style scoped>
.searchBox {
  display: flex;
}

.search {
  display: flex;
  min-width: 95%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.searchButton {
  color: rgba(11, 11, 11, 0.8);
  margin-left: -1.1rem;
  width: 1rem;
  height: 1rem;
  align-self: center;
  align-items: center;
  font-size: 0.6rem;
  border: none;
}

</style>