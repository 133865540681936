<template>
  <div>
     <header>
          <h1>Films</h1>
          <button v-if="!addMode" class="AddButton" v-show="!addMode" @click="toggleEditMode"><i class="fas fa-plus"></i></button>
          <button v-else class="AddButton red" @click="addMode=!addMode">X</button>
      </header>
      <main>
          <form ref="form" class="AddForm collapsed" v-on:submit.prevent="addElem" v-if="addMode">
            <label for="Director">Director</label>
            <input id="Director" v-model="formAdd.director" placeholder="director"/>
            <label for="Title">Title</label>
            <input id="Title" v-model="formAdd.title" placeholder="title"/>
            <label for="Actors">Actors</label>
            <input id="Actors" v-model="formAdd.actors" placeholder="Actors"/>
            <label for="Length">Length</label>
            <input id="Length" type="number" v-model="formAdd.length" placeholder="Lenght (in min)"/>
            <label for="Producer">Producer</label>
            <input id="Producer" v-model="formAdd.producer" placeholder="Producer"/>
            <label for="Type">Type</label>
            <input id="Type" v-model="formAdd.type" placeholder="type"/>
            <button class="button edit">
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </form>
          <SearchHeader page="Films"/>
          <TableView ref="table" :headings="headings" width="10rem">
            <tr :key="film" v-for="film in this.$store.getters.getFilms">
              <td v-if="editRow === film.Number">
                <DropDown 
                  :values="this.filmFields.directors"
                  field="Director"
                  :inputValue="film.Director"
                  @selected="(n) => {formEdit.director = n}"
                />
              </td>
              <td v-else>{{ film.Director }}</td>
              <td v-if="editRow === film.Number">
                <DropDown 
                  :values="this.filmFields.titles"
                  field="Title"
                  :inputValue="film.Title"
                  @selected="(n) => {formEdit.title = n}"
                />
              </td>
              <td v-else>{{ film.Title }}</td>
              <td v-if="editRow === film.Number">
                <DropDown 
                  :values="this.filmFields.actors"
                  field="Actors"
                  :inputValue="film.Actors"
                  @selected="(n) => {formEdit.actors = n}"
                />
              </td>
              <td v-else>{{ film.Actors }}</td>
              <td v-if="editRow === film.Number">
                <input :placeholder="film.Length" v-model="formEdit.length"/>
              </td>
              <td v-else>{{ film.Length }}</td>
              <td v-if="editRow === film.Number">
                <DropDown 
                  :values="this.filmFields.producers"
                  field="Producer"
                  :inputValue="film.Producer"
                  @selected="(n) => {formEdit.producer = n}"
                />
              </td>
              <td v-else>{{ film.Producer }}</td>
              <td v-if="editRow === film.Number">
                <DropDown 
                  :values="this.filmFields.types"
                  field="Type"
                  :inputValue="film.Type"
                  @selected="(n) => {formEdit.type = n}"
                />
              </td>
              <td v-else>{{ film.Type }}</td>
              <div class="button-panel">
                <button class="button edit" @click="editElem(film.Number)">
                  <i v-if="editRow === film.Number" class="fa fa-check" aria-hidden="true"></i>
                  <i v-else class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
                <button class="button edit" @click="removeElem(film.Number)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
              </div>
            </tr>
          </TableView>
      </main>
     <footer v-if="$store.getters.getFilmPageNb <= 5">
        <button class="page" v-for="index in $store.getters.getFilmCurrentPage + 1" :key="index">{{ index }}</button>
      </footer>
      <footer v-else>
        <button @click="fetchPage(0)" class="page"> {{ '<<' }} </button>
        <button
          class="page"
          :class="{ 'red': $store.getters.getFilmCurrentPage - Math.max(0, Math.min($store.getters.getFilmCurrentPage - 2, $store.getters.getFilmPageNb - 5)) === index}" 
          v-for="(n, index) in (Math.max(5, Math.min($store.getters.getFilmCurrentPage + 2, $store.getters.getFilmPageNb)) - Math.max(0, Math.min($store.getters.getFilmCurrentPage - 2, $store.getters.getFilmPageNb - 5)))"
          :key="index"
          @click="fetchPage(Math.max(0, Math.min($store.getters.getFilmCurrentPage - 2, $store.getters.getFilmPageNb - 5)) + index)">
          {{ Math.max(0, Math.min($store.getters.getFilmCurrentPage - 2, $store.getters.getFilmPageNb - 5)) + index }}
        </button>
        <p v-if="$store.getters.getFilmCurrentPage !== $store.getters.getFilmPageNb" class="elipsis">...</p>
        <button v-if="$store.getters.getFilmCurrentPage !== $store.getters.getFilmPageNb" class="page" @click="fetchPage($store.getters.getFilmPageNb)">{{ $store.getters.getFilmPageNb }}</button>
        <button @click="fetchPage($store.getters.getFilmPageNb)" class="page"> >> </button>
      </footer>
  </div>
</template>

<script>
import TableView from '../components/TableView.vue';
import DropDown from '../components/DropDown.vue';
import SearchHeader from '../components/SearchHeader.vue';

export default {
  components: {
      TableView,
      DropDown,
      SearchHeader
  },
  data() {
    return {
      headings: ['Director', 'Title', 'Actors', 'Length', 'Producer', 'Type'],
      editMode: false,
      editRow: undefined,
      addMode: false,
      formEdit: {},
      formAdd: {},
      search: "",
      sort: "",
      filmFields: {},
    }
  },
  mounted() {
    this.$store.dispatch('getFilmField', 'Director').then((res) => {
      this.filmFields.directors = [...res];
    })
    this.$store.dispatch('getFilmField', 'Title').then((res) => {
      this.filmFields.titles = [...res];
    })
    this.$store.dispatch('getFilmField', 'Actors').then((res) => {
      this.filmFields.actors = [...res];
    })
    this.$store.dispatch('getFilmField', 'Producer').then((res) => {
      this.filmFields.producers = [...res];
    })
    this.$store.dispatch('getFilmField', 'Type').then((res) => {
      this.filmFields.types = [...res];
    })
  },
  methods: {
    removeElem(id) {
      this.$store.dispatch('removeFilm', id)
    },
    toggleEditMode() {
      this.addMode =! this.addMode
    },
    addElem() {
      if (!this.formAdd.title || !this.formAdd.director 
          || !this.formAdd.producer || !this.formAdd.type
          || !this.formAdd.actors || !this.formAdd.length) {
            //TODO: error handling
            return
          }
      this.$store.dispatch('addFilm', {
        'Title': this.formAdd.title, 
        'Director': this.formAdd.director,
        'Producer': this.formAdd.producer,
        'Type': this.formAdd.type,
        'Actors': this.formAdd.actors,
        'Length': this.formAdd.length,
        'Number': new Date().valueOf()
      })
      this.addMode = true
      this.formAdd = {}
    },
    editElem(id) {
      this.editMode =! this.editMode
      if (this.editMode || this.editRow !== id) {
        this.editRow = id
      }
      else {
        this.editRow = undefined;
        let ele = this.$store.getters.getFilms.filter(test => test.Number === id)[0]
        let newObj = {Director: '', Title: '', Producer: '', Type: '', Length: '', Actors: ''}
        if (ele) {
          newObj.Director = this.formEdit.director ? this.formEdit.director : ele.Director
          newObj.Producer = this.formEdit.producer ? this.formEdit.producer : ele.Producer
          newObj.Title = this.formEdit.title ? this.formEdit.title : ele.Title
          newObj.Type = this.formEdit.type ? this.formEdit.type : ele.Type
          newObj.Length = this.formEdit.length ? this.formEdit.length : ele.Length
          newObj.Actors = this.formEdit.actors ? this.formEdit.actors : ele.Actors
          newObj.Number = id
        } else {
          newObj.Director = this.formEdit.director ? this.formEdit.director : ""
          newObj.Producer = this.formEdit.producer ? this.formEdit.producer : ""
          newObj.Title = this.formEdit.title ? this.formEdit.title : ""
          newObj.Type = this.formEdit.type ? this.formEdit.type : ""
          newObj.Length = this.formEdit.length ? this.formEdit.length : ""
          newObj.Actors = this.formEdit.actors ? this.formEdit.actors : ""
          newObj.Number = id
        }
        this.$store.dispatch('editFilm', {id: id, newfilm: newObj})
        this.formEdit = {}
      }
    },
    fetchPage(page, nooption=false) {
      this.$store.commit('setFilmCurrentPage', page)
      if (nooption) {
        this.$store.dispatch('getFilms', {page: page, search: "", order: ""})
      } else {
        this.$store.dispatch('getFilms', {page: page, search: this.search, order: this.sort})
      }
    },
  }
}
</script>
<style scoped>
main {
  width: 80vw;
}

table {
  margin-left: auto;
  margin-right: auto;
}

.elipsis {
  font-size: 3rem
}

.page {
  border: none;
  padding: 1rem;
  margin: 0.2rem;
}

footer {
  display: flex;
  padding: 0.2rem;
  align-items: center;
}


</style>