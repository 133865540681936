<template>
  <div>
      <header>
          <h1>Books</h1>
          <button v-if="!addMode" class="AddButton" v-show="!addMode" @click="toggleEditMode"><i class="fas fa-plus"></i></button>
          <button v-else class="AddButton red" @click="addMode=!addMode">X</button>
      </header>
      <main>
          <form ref="form" class="AddForm collapsed" v-on:submit.prevent="addElem" v-if="addMode">
            <label for="author">Author</label>
            <input id="author" v-model="formAdd.author" placeholder="Author"/>
            <label for="title">Title</label>
            <input id="title" v-model="formAdd.title" placeholder="Title"/>
            <label for="editor">Editor</label>
            <input id="editor" v-model="formAdd.editor" placeholder="Editor"/>
            <label for="type">Type</label>
            <input id="type" v-model="formAdd.type" placeholder="Type"/>
            <button class="button edit">
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </form>
          <SearchHeader page="Books" @changeSearch="(s) => {editSearch(s)}"/>
          <TableView ref="table" :headings="headings" width="15rem">
            <tr v-for="book in this.$store.getters.getBooks" :key="book">
              <td v-if="editRow === book.biblio_Index">
                <DropDown
                  :values="this.bookFields.authors"
                  field="Auteur"
                  :inputValue="book.Auteur"
                  @selected="(n) => {formEdit.author = n}"
                  />
              </td>
              <td v-else>{{ book.Auteur }}</td>
              <td v-if="editRow === book.biblio_Index">
                <DropDown
                  :values="this.bookFields.titles"
                  field="Titre"
                  :inputValue="book.Titre"
                  @selected="(n) => {formEdit.title = n}"
                  />
              </td>
              <td v-else>{{ book.Titre }}</td>
              <td v-if="editRow === book.biblio_Index">
                <DropDown
                  :values="this.bookFields.editors"
                  field="Editeur"
                  :inputValue="book.Editeur"
                  @selected="(n) => {formEdit.editor = n}"
                  />
              </td>
              <td v-else>{{ book.Editeur }}</td>
              <td v-if="editRow === book.biblio_Index">
                <DropDown
                  :values="this.bookFields.types"
                  field="Type"
                  :inputValue="book.Type"
                  @selected="(n) => {formEdit.type = n}"
                  />
              </td>
              <td v-else>{{ book.Type }}</td>
              <div class="button-panel">
                <button class="button edit" @click="editElem(book.biblio_Index)">
                  <i v-if="editRow === book.biblio_Index" class="fa fa-check" aria-hidden="true"></i>
                  <i v-else class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
                <button class="button edit" @click="removeElem(book.biblio_Index)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
              </div>
            </tr>
          </TableView>
      </main>
      <PaginationFooter/>
  </div>
</template>

<script>
import TableView from '../components/TableView.vue';
import PaginationFooter from '../components/PaginationFooter.vue';
import SearchHeader from '../components/SearchHeader.vue';
import DropDown from '../components/DropDown.vue';

export default {
  components: {
      TableView,
      PaginationFooter,
      SearchHeader,
      DropDown
  },
  data() {
    return {
      headings: ['Author', 'Title', 'Editor', 'Type'],
      editMode: false,
      editRow: undefined,
      addMode: false,
      formEdit: {},
      formAdd: {},
      search: "",
      sort: "",
      bookFields: {},
    }
  },
  mounted() {
    this.$store.dispatch('getBookField', 'Auteur').then((res) => {
      if (res === null) {
        this.$store.dispatch('logout');
        return;
      }
      this.bookFields.authors = [...res];

    })
    this.$store.dispatch('getBookField', 'Titre').then((res) => {
      if (res === null) {
        this.$store.dispatch('logout');
        return;
      }
      this.bookFields.titles = [...res];
    })
    this.$store.dispatch('getBookField', 'Editeur').then((res) => {
      if (res === null) {
        this.$store.dispatch('logout');
        return;
      }
      this.bookFields.editors = [...res];
    })
    this.$store.dispatch('getBookField', 'Type').then((res) => {
      if (res === null) {
        this.$store.dispatch('logout');
        return;
      }
      this.bookFields.types = [...res];
    })
  },
  methods: {
    removeElem(id) {
      this.$store.dispatch('removeBook', id)
    },
    toggleEditMode() {
      this.addMode =! this.addMode
    },
    editSearch(search) {
      this.search = search;
    },
    addElem() {
      if (!this.formAdd.title || !this.formAdd.author)
        return
      this.$store.dispatch('addBook', {
        'Titre': this.formAdd.title, 
        'Auteur': this.formAdd.author,
        'Editeur': this.formAdd.editor,
        'Type': this.formAdd.type,
        'biblio_Index': new Date().valueOf()
      })
      this.addMode = true
      this.formAdd = {}
    },
    editElem(id) {
      this.editMode =! this.editMode
      if (this.editMode || this.editRow !== id) {
        let currentBook = this.$store.getters.getBooks.filter(b => b.biblio_Index === id)
        this.formEdit.author = currentBook[0].Auteur
        this.formEdit.title = currentBook[0].Titre
        this.formEdit.editor = currentBook[0].Editeur
        this.formEdit.type = currentBook[0].Type
        this.editRow = id
      }
      else {
        this.editRow = undefined
        let book = this.$store.getters.getBooks.filter(b => b.biblio_Index === id)[0]
        let newObj = {author: '', title: '', type: '', editor: ''}
        if (book) {
          newObj.author = this.formEdit.author ? this.formEdit.author : book.Auteur
          newObj.title = this.formEdit.title ? this.formEdit.title : book.Titre
          newObj.type = this.formEdit.type ? this.formEdit.type : book.Type
          newObj.editor = this.formEdit.editor ? this.formEdit.editor : book.Editeur
          newObj.id = id
        } else {
          newObj.author = this.formEdit.author ? this.formEdit.author : ""
          newObj.title = this.formEdit.title  ? this.formEdit.title : ""
          newObj.type = this.formEdit.type  ? this.formEdit.type : ""
          newObj.editor = this.formEdit.editor ? this.formEdit.editor : ""
          newObj.id = id;
        }
        this.$store.dispatch('editBook', {id: id, newBook: newObj, search: this.search})
        this.formEdit = {}
      }
    },
  }
}
</script>
<style scoped>
main {
  max-width: 60.5vw;
}

td input {
  width: 14.5rem;
}

@keyframes lineInsertedIn {
  0% {
    height: 0rem;
  }
  100% {
    height: 23rem;
  }
}
</style>